.server-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 10%;
}

.server-card {
    background-color: #181818;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

h2 {
    margin-bottom: 20px;
    color: #fff;
}

.server-list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
}

.server-item {
    background-color: #202020;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    color: #ccc;
    text-align: left;
}

.server-item h3 {
    margin: 0 0 10px 0;
    color: #fff;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #800080;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

button:hover {
    background-color: #b300b3;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #181818;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
    max-width: 400px;
}

.modal-content h2 {
    margin-bottom: 20px;
    color: #fff;
}

.modal-content form div {
    margin-bottom: 15px;
    text-align: left;
}

.modal-content label {
    display: block;
    margin-bottom: 5px;
    color: #ccc;
}

.modal-content input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.modal-content input[type="range"] {
    width: 100%;
}

.modal-content span {
    color: #fff;
    margin-left: 10px;
}

.modal-content button {
    width: 100%;
    padding: 10px;
    background-color: #800080;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.modal-content button:hover {
    background-color: #b300b3;
}