.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 10%;  /* Отступы по краям */
}

.login-box {
  background-color: #181818;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 400px;  /* Максимальная ширина окна */
  margin: 0 auto;
}

h2 {
  margin-bottom: 20px;
}

form div {
  margin-bottom: 15px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #800080; /* Фиолетовая кнопка */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #b300b3;
}

a {
  color: #fff;
  text-decoration: underline;
}

a:hover {
  color: #b300b3;
  text-decoration: none;
}
