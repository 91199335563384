@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body, html {
  font-family: 'Montserrat', sans-serif;
  background-color: #181818;
  color: #fff;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;  /* Убираем скролл */
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
}

.workspace-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #121212;
  box-sizing: border-box;
}
