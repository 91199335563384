.workspace-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  background-color: #181818;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative; /* Для того, чтобы позиционирование меню было относительно хедера */
  z-index: 10; /* Поднимает хедер над остальным содержимым */
}

.username-section {
  display: flex;
  align-items: center;
}

.username {
  margin-right: 10px;
  font-size: 16px;
  color: #fff;
}

.dropdown-toggle {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-menu {
  position: absolute; /* Абсолютное позиционирование */
  top: 100%; /* Начинается сразу под хедером */
  right: 0;
  background-color: #202020;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  z-index: 20; /* Устанавливаем меню поверх остального содержимого */
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu li {
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
}

.dropdown-menu li:hover {
  background-color: #333;
}
