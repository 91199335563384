/* Убедитесь, что body и html занимают всю высоту и ширину */
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;  /* Убираем прокрутку */
}

/* Контейнер для страницы */
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;  /* Добавляем 100% ширины */
  text-align: center;
  position: relative;
  background-size: cover;  /* Растягиваем фон */
  background-position: center;  /* Центрируем фон */
  background-repeat: no-repeat;  /* Запрещаем повторение */
  background-attachment: fixed;  /* Фиксируем фон */
}

.home-logo {
  width: 200px;
  height: auto;
  border: none;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  z-index: 1;
}

.home-logo:hover {
  transform: scale(1.2);
  overflow: visible;
}

.animated-text {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 20px;
  font-family: 'Courier New', Courier, monospace;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: #ffffff;
  margin-left: 2px;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.home-logo:active {
  transform: scale(1.1);
}

.login-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeIn 0.5s ease-in-out;
  z-index: 2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9) translate(-50%, -50%);
  }
  to {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}
